import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "Home",
    isTitle: true,
    role: "admin",
  },
  {
    id: 2,
    label: "Home",
    icon: "bx-home-circle",
    link: "/dashboard",
    role: "admin",
  },
  {
    id: 15,
    label: "Pages",
    isTitle: true,
    role: "admin",
  },

  {
    id: 7,
    label: "Pages",
    link: "/pages/list",
    icon: "bx-spreadsheet",
    role: "admin",
  },
  {
    id: 8,
    label: "New Page",
    link: "/pages/create",
    icon: "bx-list-plus",
    role: "admin",
  },

  {
    id: 20,
    label: "Regions",
    isTitle: true,
    role: "admin",
  },

  {
    id: 21,
    label: "Cities",
    link: "/cities",
    icon: "bx-world",
    role: "admin",
  },

  {
    id: 21,
    label: "Districts",
    link: "/districts",
    icon: "bx-world",
    role: "admin",
  },

  {
    id: 89,
    label: "Users",
    isTitle: true,
    role: "admin",
  },

  {
    id: 89,
    label: "Users",
    link: "/users",
    icon: "bx bx-group",
    role: "admin",
  },

  {
    id: 20,
    label: "Categories",
    isTitle: true,
    role: "admin",
  },

  {
    id: 21,
    label: "Categories",
    link: "/categories",
    icon: "bx-book-open",
    role: "admin",
  },

  {
    id: 29,
    label: "Stores",
    isTitle: true,
    role: "admin",
  },
  {
    id: 30,
    label: "Stores",
    icon: "bx-store-alt",
    link: "/stores",
    role: "admin",
  },
  {
    id: 101,
    label: "Reservations",
    isTitle: true,
    role: "admin",
  },
  {
    id: 101,
    label: "Reservations",
    link: "/reservations",
    icon: "bx bx-cart",
    role: "admin",
  },
  {
    id: 22,
    label: "Services",
    isTitle: true,
    role: "admin",
  },
  {
    id: 23,
    label: "Services",
    icon: "bx-wrench",
    link: "/services",
    role: "admin",
  },

  {
    id: 201,
    label: "Notification",
    isTitle: true,
    role: "admin",
  },
  {
    id: 201,
    label: "Notification",
    icon: "bx bx-bell",
    link: "/notification",
    role: "admin",
  },
  {
    id: 5,
    label: "Socials",
    isTitle: true,
    role: "admin",
  },
  {
    id: 6,
    label: "Socials",
    icon: "bx bxs-cog",
    link: "/socials",
    role: "admin",
  },
];
